<template>
  <Dialog :common-dialog="reassignScheduleDialog" :dialog-width="dialogWidth">
    <template v-slot:title>
      Assign & Re-Schedule ({{ visitArr && visitArr.length }})</template
    >
    <template v-slot:body>
      <v-container class="px-10" fluid>
        <v-form
          ref="mail_form"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="submit()"
        >
          <v-row>
            <v-col md="4">
              <label class="font-weight-700 font-size-16">Start Date </label>
              <DatePicker
                solo
                flat
                :nullable="true"
                placeholder="Start Date"
                v-model="reassignSchedule.start_date"
                :default-date="reassignSchedule.start_date"
                :offWeekend="false"
              ></DatePicker>
            </v-col>
            <v-col md="4">
              <label
                class="font-weight-700 font-size-16"
                for="one-off-start-time"
                >Start Time
              </label>
              <TimePicker
                key="visit-schedule-start-time"
                id="schedule-start-time"
                placeholder="Start Time"
                v-model="reassignSchedule.start_time"
                v-on:change="addTime(reassignSchedule)"
              />
              <!--  <vue-timepicker
                :format="yourFormat"
                :minute-interval="15"
                id="one-off-start-time"
                class="b-timepicker"
                input-width="100%"
                now-button
                placeholder="Start Time"
                dense
                outline
                tile
                reset-button
                locale="en"
                v-model.trim="reassignSchedule.start_time"
                v-on:change="update_time($event, reassignSchedule.start_time)"
              >
              </vue-timepicker> -->
            </v-col>
            <v-col md="4">
              <label class="font-weight-700 font-size-16" for="one-off-end-time"
                >End Time
              </label>
              <TimePicker
                key="visit-schedule-end-time"
                id="schedule-end-time"
                placeholder="End Time"
                v-model="reassignSchedule.end_time"
              />
              <!-- <vue-timepicker
                :format="yourFormat"
                :minute-interval="15"
                id="one-off-end-time"
                class="b-timepicker"
                input-width="100%"
                now-button
                placeholder="End Time"
                dense
                outline
                tile
                reset-button
                locale="en"
                v-model="reassignSchedule.end_time"
                v-on:change="update_end_time($event, reassignSchedule.end_time)"
              >
              </vue-timepicker> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <label class="font-weight-700 font-size-16"
                >Select Customer</label
              >
              <CustomerFilter />
            </v-col>
            <v-col md="4">
              <label
                class="font-weight-700 font-size-16"
                for="one-off-start-time"
                >Select Contact Person</label
              >
              <PersonFilter />
            </v-col>
            <v-col md="4">
              <label class="font-weight-700 font-size-16" for="one-off-end-time"
                >Select Service Location
              </label>
              <PropertyFilter />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <label class="font-weight-700 font-size-16"
                >Select Technician</label
              >
              <EngineerFilter />
            </v-col>
            <v-col
              md="8"
              v-if="
                ltxFilter.multiple_engineer &&
                ltxFilter.multiple_engineer.length > 0
              "
            >
              <label
                class="font-weight-800 font-size-16"
                style="color: red !important"
                ><!-- All exiting staff will be unassign --></label
              >
              <v-radio-group
                v-model="reassignSchedule.activated"
                row
                hide-details
                class="mt-0"
              >
                <v-radio
                  style="margin-right: -2px"
                  :value="1"
                  color="cyan"
                ></v-radio>
                <v-chip
                  label
                  color="cyan"
                  outlined
                  class="text-white p-3 mr-5"
                  small
                  >Merge selected staff with existing staff</v-chip
                >
                <v-radio
                  :value="0"
                  color="red"
                  style="margin-right: -2px"
                ></v-radio>
                <v-chip label color="red" outlined class="text-white p-3" small
                  >Replace selected staff with existing staff</v-chip
                >
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row v-if="false && scheduleChanged">
            <v-col md="12">
              <p class="font-weight-600 red--text font-size-15">
                Note: If the selection includes any recurring visit, it will be
                converted into a one-off visit.
              </p>
            </v-col>
          </v-row>
          <!--     <v-row>
            <v-col md="6">
              <div class="d-flex">
                <v-checkbox
                  color="cyan"
                  hide-details
                  v-model.trim="reassignSchedule.custom"
                  class="m-0 p-0"
                ></v-checkbox>
                <label for="" class="font-weight-700 margin-2px">Custom</label>
              </div>
            </v-col>
            <v-col>
              <label class="font-weight-700 font-size-16 required"
                >Select Technician</label
              >
              <v-autocomplete
                :rules="[
                  validateRules.required(reassignSchedule.Technician, 'Driver'),
                ]"
                dense
                color="cyan"
                filled
                id="job-type"
                :items="engineerList"
                :disabled="formLoading"
                :loading="formLoading"
                label="Select Technician"
                solo
                flat
                item-color="cyan"
                placeholder="Select Technician"
                item-text="display_name"
                v-model.trim="reassignSchedule.Technician"
                item-value="id"
                hide-details
                multiple
                class="width-100"
              >
                <template v-slot:item="data">
                  <table>
                    <tr>
                      <td>
                        <span class="mr-2">{{ data.item.display_name }}</span>
                        <template v-if="data.item && data.item.role == 2">
                          <v-chip
                            label
                            outlined
                            text-color=""
                            dense
                            small
                            color="blue"
                            style="max-width: 72px !important"
                            >Technician</v-chip
                          >
                        </template>
                        <template v-if="data.item && data.item.role == 3">
                          <v-chip
                            label
                            outlined
                            text-color=""
                            small
                            style="max-width: 48px !important"
                            color="red"
                          >
                            Driver</v-chip
                          >
                        </template>
                      </td>
                    </tr>
                  </table>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row> -->
          <!--  -->
        </v-form>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        v-on:click="close_dialog()"
        :disabled="formLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Close
      </v-btn>

      <v-btn
        v-on:click="submit()"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        :loading="formLoading"
        :disabled="!formValid || formLoading"
      >
        Save
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CustomerFilter from "@/view/pages/partials/Filter/Customer-Filter.vue";
import PropertyFilter from "@/view/pages/partials/Filter/Property-Filter.vue";
import PersonFilter from "@/view/pages/partials/Filter/Person-Filter.vue";
import EngineerFilter from "@/view/pages/partials/Filter/Multiple-Engineer-Filter.vue";
import Dialog from "@/view/pages/partials/Dialog";
/*   import { validateEmail } from "@/core/plugins/validation-mixin"; */
import { PUT, QUERY } from "@/core/services/store/request.module";
import AppConfiguration from "@/core/config/app.config";
/* import DatePicker from "@/view/pages/partials/Datepicker.vue"; */
import moment from "moment-timezone";
/* import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue"; */
import DatePicker from "@/view/components/DatePicker.vue";
import TimePicker from "@/view/components/TimePickerNew.vue";
import { CLEAR_FILTER } from "@/core/services/store/filter.module";
import { mapGetters } from "vuex";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  props: {
    reassignScheduleDialog: {
      type: Boolean,
      default: false,
    },
    visitArr: {
      type: Array,
      default: () => {
        return new Array();
      },
    },
    ticketId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      formLoading: false,
      formValid: true,
      filtered_property: [],
      filtered_person: [],
      customerList: [],
      all_property: [],
      contactPersonList: [],
      date: moment().format("YYYY-MM-DD"),
      yourFormat: "hh:mm A",
      startDatePicker: false,
      engineerList: [],
      timeCustomSlot: [],
      reassignSchedule: {
        visitArr: [],
        start_date: null,
        technician: [],
        activated: 1,
        property: 0,
        customer: 0,
        person: 0,
        end_date: null,
        start_time: null,
        end_time: null,
      },
    };
  },
  methods: {
    addTime(row) {
      this.reassignSchedule.end_time = moment(row.start_time, "hh:mm A")
        .add(60, "minutes")
        .format("hh:mm A");
    },
    close_dialog() {
      this.$emit("close", true);
    },
    update_time(date) {
      this.reassignSchedule.start_time = date.displayTime;
    },
    update_end_time(date) {
      // console.log(date);
      this.reassignSchedule.end_time = date.displayTime;
    },
    filter_property(param) {
      this.filtered_property = this.lodash.filter(this.all_property, {
        contact_person: param,
        customer: this.reassignSchedule.customer,
      });
    },
    filter_person(param) {
      this.filtered_person = this.lodash.filter(this.contactPersonList, {
        customer: param,
      });
    },
    get_formatted_property(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }
      if (row.zip_code) {
        tempArr.push(row.zip_code);
      }
      if (row.country_name) {
        tempArr.push(row.country_name);
      }
      return tempArr.join(", ");
    },
    getAvailableTeam() {
      const _this = this;

      _this.$store
        .dispatch(QUERY, {
          url: "job/available-engineers",
          data: {
            start_date: _this.reassignSchedule.start_date
              ? moment(_this.reassignSchedule.start_date).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD"),
            end_date: _this.reassignSchedule.end_date
              ? moment(_this.reassignSchedule.end_date).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD"),
            start_time: _this.reassignSchedule.start_time
              ? moment(_this.reassignSchedule.start_time, ["hh:mm A"]).format(
                  "HH:mm"
                )
              : moment().startOf("day").format("HH:mm"),
            end_time: _this.reassignSchedule.end_time
              ? moment(_this.reassignSchedule.end_time, ["hh:mm A"]).format(
                  "HH:mm"
                )
              : moment().endOf("day").format("HH:mm"),
            force: 0,
            all: 1,
            /*  skills: _this.skills, */
          },
        })
        .then(({ data }) => {
          _this.engineerList = data.engineers;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.teamLoading = false;
        });
    },

    submit() {
      const _this = this;

      /*  if (
        !_this.reassignSchedule.start_date &&
        (_this.reassignSchedule.start_time || _this.reassignSchedule.end_time)
      ) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select Start Date.")
        );
        return false;
      } */

      if (
        _this.reassignSchedule?.start_date == null &&
        _this.reassignSchedule?.start_time == null &&
        _this.reassignSchedule?.end_time == null &&
        _this.reassignSchedule?.end_date == null &&
        (_this.ltxFilter?.customer == null ||
          _this.ltxFilter?.customer?.id == 0) &&
        (_this.ltxFilter?.person == null || _this.ltxFilter?.person?.id == 0) &&
        (_this.ltxFilter?.property == null ||
          _this.ltxFilter?.property?.id == 0) &&
        _this.ltxFilter?.multiple_engineer?.length == 0
      ) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select atleast one field.")
        );
        return false;
      }

      if (_this.ltxFilter?.customer?.id && _this.ltxFilter?.customer?.id) {
        if (!_this.ltxFilter?.person || _this.ltxFilter?.person?.id == 0) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Select Contact Person then try again.")
          );
          return false;
        }

        if (!_this.ltxFilter?.property || _this.ltxFilter?.property?.id == 0) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Select Service Location then try again.")
          );
          return false;
        }
      }

      // this.reassignSchedule.visitArr = this.visitArr;
      _this.formLoading = true;
      (_this.reassignSchedule.start_date = _this.reassignSchedule.start_date
        ? moment(_this.reassignSchedule.start_date).format("YYYY-MM-DD")
        : null),
        (_this.reassignSchedule.end_date = _this.reassignSchedule.end_date
          ? moment(_this.reassignSchedule.end_date).format("YYYY-MM-DD")
          : null),
        (_this.reassignSchedule.start_time = _this.reassignSchedule.start_time
          ? moment(_this.reassignSchedule.start_time, ["hh:mm A"]).format(
              "hh:mm A"
            )
          : null),
        (_this.reassignSchedule.end_time = _this.reassignSchedule.end_time
          ? moment(_this.reassignSchedule.end_time, ["hh:mm A"]).format(
              "hh:mm A"
            )
          : null);

      const payload = {
        visitArr: _this.visitArr,
        technician: _this.ltxFilter?.multiple_engineer?.map((row) => row.id),
        customer: _this.ltxFilter?.customer?.id,
        activated: _this.reassignSchedule.activated,
        property: _this.ltxFilter?.property?.id,
        person: _this.ltxFilter?.person?.id,
        start_date: _this.reassignSchedule.start_date,
        start_time: _this.reassignSchedule.start_time,
        end_date: _this.reassignSchedule.end_date,
        end_time: _this.reassignSchedule.end_time,
        schedule_changed: _this.scheduleChanged,
      };

      _this.$store
        .dispatch(PUT, { url: "re-assign/visit", data: payload })
        .then(() => {
          _this.$emit("success", true);
          _this.close_dialog();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  components: {
    Dialog,
    CustomerFilter,
    PropertyFilter,
    PersonFilter,
    EngineerFilter,
    DatePicker,
    TimePicker,
  },
  beforeDestroy() {
    this.$store.dispatch(CLEAR_FILTER);
  },
  mounted() {
    this.$store.dispatch(CLEAR_FILTER);
  },
  computed: {
    ...mapGetters(["ltxFilter"]),
    scheduleChanged() {
      return (
        (this.reassignSchedule.start_date !== null &&
          this.reassignSchedule.start_date !== "") ||
        (this.reassignSchedule.start_time !== null &&
          this.reassignSchedule.start_time !== "") ||
        (this.reassignSchedule.end_time !== null &&
          this.reassignSchedule.end_time !== "")
      );
    },
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
    todayDate() {
      return moment().format("YYYY-MM-DD");
    },
    formattedStartDate() {
      let Config = AppConfiguration.get();
      if (Config) {
        return moment(this.reassignSchedule.start_date).format(
          Config.dateFormat
        );
      }
      return this.reassignSchedule.start_date;
    },
  },
};
</script>
