<template>
  <v-menu
    v-model="ltxFilterDropDown.visit_status"
    content-class="bg-white"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="d-flex justify-space-between">
        <v-list :disabled="disabled" class="py-0" color="cyan--text" dense>
          <v-list-item class="pl-0" dense>
            <v-list-item-icon class="mr-2">
              <v-icon :color="ltxFilter?.visit_status?.color" size="30"
                >mdi-record</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content class="text-ellipsis max-width-400px">
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
                style="line-height: unset"
                v-if="ltxFilter?.visit_status?.text"
                >{{ ltxFilter?.visit_status?.text }}</v-list-item-title
              >
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
                style="line-height: unset"
                v-else
                >Select Status</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-action class="align-self-center my-0">
              <v-list-item-subtitle
                class="text-capitalize font-weight-500 font-size-16"
                style="line-height: unset"
                v-if="ltxFilter?.visit_status?.count"
                >({{ ltxFilter?.visit_status?.count }})</v-list-item-subtitle
              >
              <v-list-item-subtitle
                class="text-capitalize font-weight-500 font-size-16"
                style="line-height: unset"
                v-else
                >(0)</v-list-item-subtitle
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-icon
          :class="{
            'menu-icon-open cyan--text': ltxFilterDropDown.visit_status,
          }"
          >mdi-menu-down</v-icon
        >
      </div>
    </template>
    <v-list
      class="py-0"
      color="cyan--text"
      dense
      v-if="ltxFilterVisitStatusList.length"
    >
      <v-list-item
        dense
        class="pl-1"
        selectable
        v-for="(item, index) in ltxFilterVisitStatusList"
        :input-value="ltxFilter?.visit_status?.status == item.status"
        :key="`visit-status-filter-${index}`"
        v-on:click="onClickEvent(item)"
      >
        <v-list-item-icon class="mr-2">
          <v-icon :color="item?.color" size="30">mdi-record</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="text-ellipsis max-width-400px">
          <v-list-item-title
            class="text-capitalize font-weight-500 font-size-16"
            style="line-height: unset"
            >{{ item?.text }}</v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-action class="align-self-center my-0">
          <v-list-item-subtitle
            class="text-capitalize font-weight-500 font-size-16"
            style="line-height: unset"
            >{{ item?.count }}</v-list-item-subtitle
          >
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_STATE } from "@/core/services/store/filter.module";

export default {
  name: "visit-status-filter",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickEvent(item) {
      this.setFilterState("db_filter.visit_status", item);

      this.$emit("change", item);
    },
    setFilterState(key, value) {
      this.$store.commit(SET_STATE, { key, value });
    },
  },
  computed: {
    ...mapGetters([
      "ltxFilter",
      "ltxFilterDropDown",
      "ltxFilterVisitStatusList",
      "ltxFilterLoading",
    ]),
  },
};
</script>
