<template>
  <v-menu
    v-model="ltxFilterDropDown.engineer"
    content-class="bg-white"
    left
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        style="
          height: 32px;
          padding: 0px 14px;
          border: solid 1px #bec1c7 !important;
        "
        class="font-weight-500 ml-1 d-flex align-center justify-space-between"
      >
        <template v-if="ltxFilter?.multiple_engineer?.length">
          <span
            v-for="(row, index) in ltxFilter?.multiple_engineer?.slice(0, 1)"
            :key="`engineer-more-${index}`"
            class="text-truncate"
          >
            {{ row?.id ? `${row?.barcode} - ${row?.display_name}` : "" }}
          </span>
          <span
            style="width: 95px"
            v-if="ltxFilter?.multiple_engineer?.length > 1"
          >
            &amp; {{ ltxFilter?.multiple_engineer?.length - 1 }} more...
          </span>
        </template>
        <span v-else> Select Technician </span>
        <span style="width: 24px">
          <v-icon
            :class="{ 'menu-icon-open cyan--text': ltxFilterDropDown.engineer }"
            >mdi-menu-down</v-icon
          >
        </span>
      </div>
    </template>
    <v-list height="500px" class="py-0" color="cyan--text" dense>
      <v-list-item
        class="custom-border-bottom bg-white"
        style="position: sticky; top: 0; z-index: 9999"
      >
        <v-list-item-content>
          <div class="d-flex">
            <v-text-field
              clearable
              dense
              filled
              flat
              class="pl-0"
              label="Search Technician..."
              hide-details
              solo
              v-model="filterSearch"
              color="cyan"
              v-on:keyup.enter="filterEngineer()"
              :disabled="ltxFilterLoading.engineer"
              :loading="ltxFilterLoading.engineer"
            />
            <v-btn
              v-on:click="filterEngineer()"
              :disabled="ltxFilterLoading.engineer"
              style="min-height: 32px"
              depressed
              color="cyan white--text"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="ltxFilterLoading.engineer" dense class="filter-loader">
        <v-skeleton-loader size="40" class="px-0" type="list-item-avatar" />
        <v-list-item-content class="text-ellipsis max-width-400px">
          <v-list-item-subtitle
            class="text-capitalize font-weight-500 font-size-16"
            style="line-height: unset"
          >
            <v-skeleton-loader type="chip" />
          </v-list-item-subtitle>
          <v-list-item-title
            class="text-capitalize font-weight-500 font-size-16"
            style="line-height: unset"
          >
            <v-skeleton-loader type="text" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        dense
        selectable
        v-for="(item, index) in ltxFilterEngineerList"
        :input-value="
          ltxFilter?.multiple_engineer?.some((rw) => rw.id === item.id)
        "
        :key="`engineer-filter-${index}`"
        v-on:click="onClickEvent(item)"
      >
        <v-list-item-avatar
          height="40"
          min-width="40"
          width="40"
          class="mr-2 my-2 grey lighten-2 grey--text"
        >
          <v-img v-if="item?.profile_logo" :src="item.profile_logo" />
          <span v-else class="fw-600 mb-1 font-size-20">{{
            item?.display_name?.slice(0, 2).toUpperCase()
          }}</span>
        </v-list-item-avatar>
        <v-list-item-content class="text-ellipsis max-width-400px">
          <v-list-item-subtitle
            class="text-capitalize font-weight-500 font-size-16"
            style="line-height: unset"
          >
            <span class="filter-list-barcode">{{ item?.barcode }}</span>
          </v-list-item-subtitle>
          <v-list-item-title
            class="text-capitalize font-weight-500 font-size-16"
            style="line-height: unset"
          >
            {{ item?.display_name }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action
          class="align-self-center my-0"
          v-if="ltxFilter?.multiple_engineer?.some((rw) => rw.id === item.id)"
        >
          <v-icon color="cyan">mdi-check</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_STATE, GET_ENGINEER } from "@/core/services/store/filter.module";

export default {
  name: "engineer-filter",
  data() {
    return {
      filterSearch: null,
      filterTimeout: null,
      filterTimeoutLimit: 0,
    };
  },
  watch: {
    "ltxFilterDropDown.engineer"(param) {
      if (!param && this.filterSearch) {
        this.filterSearch = null;
        this.filterEngineer();
      }
    },
  },
  methods: {
    onClickEvent(item) {
      if (!item?.id || item?.id == "UNDEFINED") {
        return false;
      }

      const multiple_engineer = this.ltxFilter.multiple_engineer;

      const itemExists = multiple_engineer.findIndex((rw) => rw.id === item.id);

      if (itemExists !== -1) {
        multiple_engineer.splice(itemExists, 1);
      } else {
        multiple_engineer.push(item);
      }

      // this.setFilterState("db_filter_dropdown.engineer", false);
      this.setFilterState("db_filter.multiple_engineer", multiple_engineer);

      this.$emit("change", multiple_engineer);
    },
    filterEngineer() {
      this.setFilterState("db_filter_engineers", []);
      this.$store.dispatch(GET_ENGINEER, { search: this.filterSearch });
    },
    setFilterState(key, value) {
      this.$store.commit(SET_STATE, { key, value });
    },
  },
  computed: {
    ...mapGetters([
      "ltxFilter",
      "ltxFilterDropDown",
      "ltxFilterEngineerList",
      "ltxFilterLoading",
    ]),
  },
};
</script>
